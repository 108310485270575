module services {
    export module costing {
        export class costParameterProductService implements interfaces.costing.ICostParameterProductService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getProductList(): ng.resource.IResourceClass<interfaces.costing.ICostProduct> {
                return this.$resource<interfaces.costing.ICostProduct>(this.ENV.DSP_URL + "CostParameterProduct/GetProductList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    countryId: '@countryId',
                    productCode: '@productCode',
                    productDescription: '@productDescription',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getProductCustomerList(): ng.resource.IResourceClass<interfaces.costing.ICostProductCustomer> {
                return this.$resource<interfaces.costing.ICostProductCustomer>(this.ENV.DSP_URL + "CostParameterProduct/GetProductCustomerList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    customerId: '@customerId',
                    productCode: '@productCode',
                    productDescription: '@productDescription',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveAll(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProduct/SaveAll", {
                });
            }

            saveProductCustomer(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProduct/SaveProductCustomer", {
                });
            }

            removeProductCosting(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProduct/RemoveProductCosting", {
                    prodId: '@prodId',
                });
            }

            removeProductCostingCustomer(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProduct/RemoveProductCostingCustomer", {
                    prodId: '@prodId',
                });
            }
        }
    }
    angular.module("app").service("costParameterProductService", services.costing.costParameterProductService);
}